// src/App.js
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';  // Only import ThemeProvider
import CssBaseline from '@mui/material/CssBaseline';
import Dashboard from './components/Dashboard';
import { theme } from './theme';

function App() {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [messageLog, setMessageLog] = useState([]);

    const addToLog = (message) => {
        const timestamp = new Date().toISOString();
        console.log(`[${timestamp}] ${message}`);
        setMessageLog(prev => [...prev, { timestamp, message }]);
    };

    useEffect(() => {
        const handleMessage = (event) => {
            addToLog(`Received message from ${event.origin}`);
            
            if (event.origin !== window.ENV.ALLOWED_ORIGIN) {
                addToLog(`Unauthorized origin: ${event.origin}`);
                return;
            }

            if (event.data.type === 'USER_DATA') {
                addToLog('Received USER_DATA');
                setUserData(event.data.user);
                setLoading(false);
            }
        };

        window.addEventListener('message', handleMessage);

        try {
            window.parent.postMessage({ 
                type: 'DRIVE_READY',
                timestamp: new Date().toISOString()
            }, window.ENV.ALLOWED_ORIGIN);
            
            addToLog('Sent DRIVE_READY message');
        } catch (err) {
            addToLog(`Error sending ready message: ${err.message}`);
            setError('Failed to initialize communication');
            setLoading(false);
        }

        return () => window.removeEventListener('message', handleMessage);
    }, []);

    if (loading) {
        return <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh' 
        }}>Loading...</div>;
    }

    if (error) {
        return <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh',
            color: 'red' 
        }}>{error}</div>;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Dashboard user={userData} />
        </ThemeProvider>
    );
}

export default App;