// src/components/Dashboard.js
import React, { useState, useCallback } from 'react';
import JsonPreview from './JsonPreview';
import { 
    Box, 
    Typography, 
    CircularProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Stack,
    Snackbar,
    Alert,
    Breadcrumbs,
    Link,
    IconButton
} from '@mui/material';
import { 
    CreateNewFolder as FolderIcon,
    Delete as DeleteIcon,
    Refresh as RefreshIcon,
    Download as DownloadIcon,
    ArrowBack as ArrowBackIcon,
    Home as HomeIcon
} from '@mui/icons-material';
import FileGrid from './FileGrid';
import { styled } from '@mui/material/styles';
import axios from 'axios';

// Styled components
const SidebarButton = styled(Button)(({ theme }) => ({
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    minWidth: '240px',
    padding: '12px 16px',
    fontSize: '0.95rem',
    backgroundColor: theme.palette.background.paper,
    '&.delete-button': {
        backgroundColor: '#36393F', // Default state
        color: '#DCDDDE',
        '&:disabled': {
            backgroundColor: '#36393F',
            color: '#72767D',
        },
        '&:enabled': {
            backgroundColor: '#ED4245', // Dashboard red when enabled
            '&:hover': {
                backgroundColor: '#A12D2F',
            }
        }
    },
    '&.new-folder-button': {
        backgroundColor: '#5865F2', // Dashboard blurple
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#4752C4',
        }
    }
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '& .preview-area': {
        height: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiButton-root': {
        padding: '12px 16px',
        fontSize: '0.95rem',
        height: '48px'
    }
}));

const Dashboard = ({ user }) => {
    // State management
    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPath, setCurrentPath] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [createFolderDialogOpen, setCreateFolderDialogOpen] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [sortConfig, setSortConfig] = useState({
        field: 'name',
        direction: 'asc'
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info'
    });
	const isPreviewable = (file) => {
    const previewableExtensions = ['.json', '.png', '.jpg', '.jpeg', '.gif', '.webp'];
    return previewableExtensions.some(ext => 
        file.path.toLowerCase().endsWith(ext)
    );
};

const isImageFile = (file) => {
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.webp'];
    return imageExtensions.some(ext => 
        file.path.toLowerCase().endsWith(ext)
    );
};
const [previewLoading, setPreviewLoading] = useState(false);

// Handle image loading errors
const handleImageError = (event) => {
    console.error('Image failed to load:', event);
    event.target.src = ''; // Clear the broken image
    setSnackbar({
        open: true,
        message: 'Failed to load image preview',
        severity: 'error'
    });
};

    // Function to handle breadcrumb navigation
    const handlePathClick = (newPath) => {
        fetchContents(newPath);
    };

    // Function to go back one directory
    const handleBackClick = () => {
        if (!currentPath) return;
        
        // Split the path and remove the last directory
        const pathParts = currentPath.split('/').filter(Boolean);
        pathParts.pop(); // Remove last element
        const newPath = pathParts.join('/');
        fetchContents(newPath);
    };

    // Handle Snackbar close
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    // Fetch contents of current directory
    const fetchContents = useCallback(async (path = '') => {
        try {
            setLoading(true);
            const response = await axios.get('https://drive.suprsketch.com/api/fs/list', {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    path: path || user.s3Path
                }
            });
            
            setFiles(response.data.files || []);
            setFolders(response.data.folders || []);
            setCurrentPath(path);
            setSelectedFiles([]);
            setLoading(false);
        } catch (err) {
            console.error('Fetch error:', err);
            setSnackbar({
                open: true,
                message: 'Failed to load contents',
                severity: 'error'
            });
            setLoading(false);
        }
    }, [user]);

    // Initial fetch
    React.useEffect(() => {
        if (user?.token && user?.s3Path) {
            fetchContents();
        }
    }, [user, fetchContents]);

    // Handle folder creation
    const handleCreateFolder = async () => {
        if (!newFolderName.trim()) {
            setSnackbar({
                open: true,
                message: 'Please enter a folder name',
                severity: 'warning'
            });
            return;
        }

        try {
            const response = await axios.post('https://drive.suprsketch.com/api/fs/folder/', {
                name: newFolderName.trim(),
                path: currentPath || user.s3Path
            }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            
            if (response.data.success) {
                setSnackbar({
                    open: true,
                    message: 'Folder created successfully',
                    severity: 'success'
                });
                setNewFolderName('');
                setCreateFolderDialogOpen(false);
                fetchContents(currentPath);
            } else {
                throw new Error(response.data.error || 'Failed to create folder');
            }
        } catch (error) {
            console.error('Create folder error:', error);
            setSnackbar({
                open: true,
                message: error.response?.data?.error || 'Failed to create folder',
                severity: 'error'
            });
        }
    };

    // Handle file/folder deletion
const handleDelete = async () => {
    if (selectedFiles.length === 0) return;

    try {
        const confirmDelete = window.confirm(
            `Are you sure you want to delete ${selectedFiles.length} item${selectedFiles.length > 1 ? 's' : ''}?`
        );

        if (!confirmDelete) return;

        let successCount = 0;
        let errorCount = 0;

        for (const item of selectedFiles) {
            try {
                console.log('Attempting to delete:', item); // Debug log
                
                // Match the server path structure
                const endpoint = item.type === 'folder' ? 'folder' : 'file';
                await axios.delete(`https://drive.suprsketch.com/api/fs/${endpoint}`, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    },
                    data: { path: item.path }
                });
                successCount++;
            } catch (err) {
                console.error('Delete error:', {
                    error: err,
                    item: item,
                    response: err.response?.data
                });
                errorCount++;
            }
        }

        if (errorCount === 0) {
            setSnackbar({
                open: true,
                message: `Successfully deleted ${successCount} item${successCount !== 1 ? 's' : ''}`,
                severity: 'success'
            });
            // Refresh the current directory
            fetchContents(currentPath);
        } else if (successCount === 0) {
            setSnackbar({
                open: true,
                message: 'Failed to delete items',
                severity: 'error'
            });
        } else {
            setSnackbar({
                open: true,
                message: `Deleted ${successCount} items, failed to delete ${errorCount} items`,
                severity: 'warning'
            });
            // Refresh even on partial success
            fetchContents(currentPath);
        }
        
        setSelectedFiles([]);
    } catch (error) {
        console.error('Delete error:', error);
        setSnackbar({
            open: true,
            message: 'Failed to delete items',
            severity: 'error'
        });
    }
};

    // Handle file download
const handleDownload = async () => {
    if (selectedFiles.length === 0) return;

    try {
        setLoading(true);

        if (selectedFiles.length === 1) {
            // Single file download
            const response = await axios.get(
                `https://drive.suprsketch.com/api/fs/download/${encodeURIComponent(selectedFiles[0].path)}`,
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    },
                    responseType: 'blob'
                }
            );

            // Create and trigger download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            // If it's a JSON file, change extension to png
            const filename = selectedFiles[0].name.endsWith('.json') 
                ? selectedFiles[0].name.replace('.json', '.png')
                : selectedFiles[0].name;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            setSnackbar({
                open: true,
                message: 'File downloaded successfully',
                severity: 'success'
            });
        } else {
            // Multiple files download
            const response = await axios.post(
                'https://drive.suprsketch.com/api/fs/download-batch',
                {
                    items: selectedFiles
                },
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    },
                    responseType: 'blob'
                }
            );

            // Create and trigger zip download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.zip');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            setSnackbar({
                open: true,
                message: 'Files downloaded successfully',
                severity: 'success'
            });
        }
    } catch (error) {
        console.error('Download error:', error);
        setSnackbar({
            open: true,
            message: 'Failed to download files',
            severity: 'error'
        });
    } finally {
        setLoading(false);
    }
};

    const handleSort = (newSortConfig) => {
        setSortConfig(newSortConfig);
        
        const sortedFiles = [...files].sort((a, b) => {
            let comparison = 0;
            
            if (newSortConfig.field === 'name') {
                comparison = a.name.localeCompare(b.name);
            } else if (newSortConfig.field === 'size') {
                comparison = (a.size || 0) - (b.size || 0);
            }
            
            return newSortConfig.direction === 'asc' ? comparison : -comparison;
        });
        
        setFiles(sortedFiles);
    };

    // Handle file selection
    const handleFileSelection = useCallback((newSelectedFiles) => {
        setSelectedFiles(newSelectedFiles);
    }, []);

    // Render breadcrumbs
const renderBreadcrumbs = () => {
    const pathParts = currentPath.split('/').filter(Boolean);
    
    // Filter out technical paths
    const visibleParts = pathParts.filter(part => {
        // Skip 'users' and the user ID
        return !part.includes('users') && !part.match(/^mem_/);
    });
    
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <IconButton 
                onClick={handleBackClick}
                disabled={!currentPath}
                sx={{ mr: 1 }}
            >
                <ArrowBackIcon />
            </IconButton>

            <Breadcrumbs sx={{ flex: 1 }}>
                <Link
                    component="button"
                    variant="body1"
                    onClick={() => handlePathClick('')}
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        color: 'text.primary',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
                    Home
                </Link>
                {visibleParts.map((part, index) => {
                    // Calculate the full path including hidden parts
                    const hiddenParts = pathParts.slice(0, pathParts.indexOf(part) + 1);
                    const fullPath = hiddenParts.join('/');
                    
                    return (
                        <Link
                            key={fullPath}
                            component="button"
                            variant="body1"
                            onClick={() => handlePathClick(fullPath)}
                            sx={{
                                color: 'text.primary',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            {part}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </Box>
    );
};

    if (loading) {
        return (
            <Box sx={{ 
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ 
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.default'
        }}>
            {/* Header */}
            <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2,
                py: 1,
                borderBottom: 1,
                borderColor: 'divider',
                bgcolor: 'background.paper'
            }}>
                <Typography variant="h6">
                    {user?.name || user?.email}'s Files
                </Typography>
                <Button
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchContents(currentPath)}
                    size="small"
                >
                    Refresh
                </Button>
            </Box>

            {/* Main Content */}
            <Box sx={{ 
                display: 'flex',
                flex: 1,
                gap: 2,
                p: 2,
                overflow: 'hidden',
				bgcolor: 'background.default'
            }}>
                {/* Sidebar */}
                <Stack sx={{ width: 240, minWidth: 240 }}>
				<SidebarButton
					className="new-folder-button"
					variant="contained"
					startIcon={<FolderIcon />}
					onClick={() => setCreateFolderDialogOpen(true)}
					size="small"
				>
					New Folder
				</SidebarButton>

				<SidebarButton
					className="delete-button"
					variant="contained"
					startIcon={<DeleteIcon />}
					onClick={handleDelete}
					disabled={selectedFiles.length === 0}
					size="small"
				>
					Delete
				</SidebarButton>
                </Stack>

                {/* FileGrid with Breadcrumbs */}
                <Box sx={{ 
                    flex: '1 1 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    overflow: 'hidden',
                    maxWidth: 'calc(100% - 860px)'
                }}>
                    {renderBreadcrumbs()}
                    <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
                        <FileGrid
                            files={files}
                            folders={folders}
                            selectedFiles={selectedFiles}
                            onSelect={handleFileSelection}
                            onFolderClick={(folder) => fetchContents(folder.path)}
                            sortConfig={sortConfig}
                            onSort={handleSort}
                        />
                    </Box>
                </Box>

{/* Preview Panel */}
<PreviewContainer>
    <Box className="preview-area">
        {previewLoading ? (
            <CircularProgress />
        ) : selectedFiles.length === 1 ? (
            isPreviewable(selectedFiles[0]) ? (
                selectedFiles[0].path.endsWith('.json') ? (
                    <JsonPreview 
                        file={selectedFiles[0]} 
                        token={user.token}
                    />
                ) : (
                    <img 
                        src={`https://drive.suprsketch.com/api/fs/download/${encodeURIComponent(selectedFiles[0].path)}`}
                        alt={selectedFiles[0].name}
                        onError={handleImageError}
                        onLoad={() => setPreviewLoading(false)}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                        }}
                    />
                )
            ) : (
                <Typography color="text.secondary">
                    Preview not available for this file type
                </Typography>
            )
        ) : (
            <Typography color="text.secondary">
                {selectedFiles.length === 0 
                    ? "Select a file to preview"
                    : `${selectedFiles.length} files selected`
                }
            </Typography>
        )}
    </Box>
    <Button
        variant="contained"
        startIcon={<DownloadIcon />}
        onClick={handleDownload}
        disabled={selectedFiles.length === 0}
        size="small"
        fullWidth
    >
        Download{selectedFiles.length > 1 ? ` (${selectedFiles.length} files)` : ''}
    </Button>
</PreviewContainer>
            </Box>

            {/* Create Folder Dialog */}
            <Dialog 
                open={createFolderDialogOpen} 
                onClose={() => setCreateFolderDialogOpen(false)}
                PaperProps={{
                    sx: { minWidth: 300 }
                }}
            >
                <DialogTitle>Create New Folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Folder Name"
                        fullWidth
                        value={newFolderName}
						onChange={(e) => setNewFolderName(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleCreateFolder();
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCreateFolderDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleCreateFolder} variant="contained">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleSnackbarClose} 
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Dashboard;