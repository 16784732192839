// src/theme.js
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#5865F2',
      dark: '#4752C4',
    },
    error: {
      main: '#ED4245',
      dark: '#A12D2F',
    },
    background: {
      default: '#202225',
      paper: '#2F3136',
      secondary: '#36393F',
    },
    text: {
      primary: '#DCDDDE',
      secondary: '#96989D',
    },
    divider: '#40444B',
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: '#36393F',
          '&:hover': {
            backgroundColor: '#32353B !important',
          },
          '&.Mui-selected': {
            backgroundColor: '#40444B !important',
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #40444B',
          color: '#DCDDDE',
          backgroundColor: 'transparent',
        },
        head: {
          backgroundColor: '#2F3136',
          color: '#B9BBBE',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#36393F',
          borderColor: '#202225',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#36393F',
            color: '#72767D',
          }
        }
      }
    }
  }
});