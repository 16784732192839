import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    Box,
    TableSortLabel,
} from '@mui/material';
import { Folder as FolderIcon } from '@mui/icons-material';

const FileGrid = ({
    files = [],
    folders = [],
    selectedFiles = [], // Keep as selectedFiles to match Dashboard state
    onSelect,
    onFolderClick,
    sortConfig,
    onSort
}) => {
    const formatSize = (bytes) => {
        if (!bytes) return '--';
        const units = ['B', 'KB', 'MB', 'GB'];
        let size = bytes;
        let unitIndex = 0;
        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }
        return `${size.toFixed(1)} ${units[unitIndex]}`;
    };

    const formatFileName = (fileName) => {
        const match = fileName.match(/imagedata-(\d+)\.json/);
        if (match) {
            const timestamp = parseInt(match[1]);
            const date = new Date(timestamp);
            return `SuprArtData - ${date.toLocaleString('en-US', {
                month: 'long',
                day: 'numeric', 
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            })}`;
        }
        return fileName;
    };

    const isSelected = (item) => {
        return selectedFiles.some(selected => selected.path === item.path);
    };

    const handleSelectOne = (item, event) => {
        event.stopPropagation();
        if (isSelected(item)) {
            const newSelection = selectedFiles.filter(selected => selected.path !== item.path);
            onSelect(newSelection);
        } else {
            const newSelection = [...selectedFiles, { ...item, type: item.type || 'folder' }];
            onSelect(newSelection);
        }
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allItems = [
                ...folders.map(folder => ({ ...folder, type: 'folder' })),
                ...files
            ];
            onSelect(allItems);
        } else {
            onSelect([]);
        }
    };

    const createSortHandler = (property) => () => {
        const isAsc = sortConfig.field === property && sortConfig.direction === 'asc';
        onSort({
            field: property,
            direction: isAsc ? 'desc' : 'asc'
        });
    };

    const totalItems = files.length + folders.length;
    const numSelected = selectedFiles.length;

    return (
        <Box sx={{ 
    width: '100%',
    bgcolor: 'background.secondary',
    '& .MuiTable-root': {
        width: '100%',
        tableLayout: 'fixed',
        bgcolor: '#2F3136', // Dashboard darker background
    },
    '& .MuiTableBody-root .MuiTableRow-root': {
        bgcolor: '#36393F', // Dashboard main content area
        '&:hover': {
            bgcolor: '#32353B !important',
        },
        '&.Mui-selected': {
            bgcolor: '#40444B !important',
            '&:hover': {
                bgcolor: '#40444B !important',
            }
        }
    },
    '& .MuiTableCell-root': {
        borderBottom: '1px solid #40444B',
        color: '#DCDDDE',
    },
    '& .MuiTableCell-head': {
        bgcolor: '#2F3136',
        color: '#B9BBBE',
    }
}}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox" sx={{ width: '48px' }}>
                            <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0 && numSelected < totalItems}
                                checked={totalItems > 0 && numSelected === totalItems}
                                onChange={handleSelectAll}
                            />
                        </TableCell>
                        <TableCell sx={{ pl: 3 }}>
						<TableSortLabel
							active={sortConfig.field === 'name'}
							direction={sortConfig.field === 'name' ? sortConfig.direction : 'asc'}
							onClick={createSortHandler('name')}
						>
							Name
						</TableSortLabel>
					</TableCell>
                        <TableCell sx={{ width: '100px' }}>
                            <TableSortLabel
                                active={sortConfig.field === 'size'}
                                direction={sortConfig.field === 'size' ? sortConfig.direction : 'asc'}
                                onClick={createSortHandler('size')}
                            >
                                Size
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* Render folders first */}
                    {folders.map((folder) => (
                        <TableRow
                            key={folder.path}
                            hover
                            onClick={(e) => {
                                if (!isSelected(folder)) {
                                    onFolderClick(folder);
                                }
                            }}
                            sx={{ cursor: 'pointer' }}
                            selected={isSelected(folder)}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={isSelected(folder)}
                                    onChange={(e) => handleSelectOne(folder, e)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </TableCell>
                            <TableCell
                                sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,  // Increased gap between items
                                    pl: 2    // Added left padding
                                }}
                            >
                                <FolderIcon color="primary" sx={{ fontSize: 20, ml: 1 }} />
                                <span>{folder.name}</span>
                            </TableCell>
							
                            <TableCell>--</TableCell>
                        </TableRow>
                    ))}
                    
                    {/* Render files */}
                    {files.map((file) => (
                        <TableRow
                            key={file.path}
                            selected={isSelected(file)}
                            hover
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={isSelected(file)}
                                    onChange={(e) => handleSelectOne(file, e)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </TableCell>

                             <TableCell
                                sx={{ 
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    pl: 3  // Added left padding
                                }}
                            >
                                {formatFileName(file.name)}
                            </TableCell>
                            <TableCell>
                                {formatSize(file.size)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default FileGrid;