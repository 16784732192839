// src/components/JsonPreview.js
import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

const JsonPreview = ({ file, token }) => {
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadJsonImage = async () => {
      if (!file) return;
      
      try {
        setLoading(true);
        setError(null);

        const response = await fetch(
          `https://drive.suprsketch.com/api/fs/download/${encodeURIComponent(file.path)}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // First try to get it as a blob
        const blob = await response.blob();
        
        // Check if it's an image type
        if (blob.type.startsWith('image/')) {
          setImageData(URL.createObjectURL(blob));
        } else {
          // If not an image, try parsing as JSON
          const text = await blob.text();
          const jsonData = JSON.parse(text);
          
          if (jsonData.data && jsonData.format) {
            const dataUrl = `data:image/${jsonData.format};base64,${jsonData.data}`;
            setImageData(dataUrl);
          } else {
            throw new Error('Invalid image data format');
          }
        }
      } catch (err) {
        console.error('Preview error:', err);
        setError(err.message || 'Failed to load image preview');
      } finally {
        setLoading(false);
      }
    };

    loadJsonImage();
    
    // Cleanup function to revoke object URLs
    return () => {
      if (imageData && imageData.startsWith('blob:')) {
        URL.revokeObjectURL(imageData);
      }
    };
  }, [file, token]);

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100%' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!imageData) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100%' 
      }}>
        <Typography color="text.secondary">
          No preview available
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      overflow: 'hidden'
    }}>
      <img
        src={imageData}
        alt="Preview"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain'
        }}
      />
    </Box>
  );
};

export default JsonPreview;